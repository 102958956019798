import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Main from './components/WriteEssay/Main'
import Navigation from './components/Navigation'
import 'bootstrap/dist/css/bootstrap.min.css'
import './Overrides.css'
import Login from './components/User/Login'
import Register from './components/User/Register'
import Reset from './components/User/Reset'
import Dashboard from './components/User/Dashboard'
import Waitlist from './components/User/Waitlist'
import Home from './components/Home/Home'
import OutputEssay from './components/WriteEssay/OutputEssay'
import ReviseEssay from './components/ReviseEssay/ReviseEssay'
import ReviseResume from './components/ReviseResume/ReviseResume'
import WriteCover from './components/WriteCover/WriteCover'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Premium from './components/Payment/Premium'
import Footer from './components/Footer'

export const config = {
  headers: {
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers':
      'Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
}

function App() {
  const [essayResult, setEssayResult] = useState()
  const [selectedPromptIndex, setSelectedPromptIndex] = useState()
  const [selectedSchoolIndex, setSelectedSchoolIndex] = useState()
  const [essayType, setEssayType] = useState('')
  return (
    <div id="bootstrap-overrides">
      <Navigation />
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            path="/essay"
            element={
              <Main
                setEssayResult={setEssayResult}
                selectedPromptIndex={selectedPromptIndex}
                setSelectedPromptIndex={setSelectedPromptIndex}
                selectedSchoolIndex={selectedSchoolIndex}
                setSelectedSchoolIndex={setSelectedSchoolIndex}
                essayType={essayType}
                setEssayType={setEssayType}
              />
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/waitlist" element={<Waitlist />} />
          <Route
            path="/results"
            element={
              <OutputEssay
                essayResult={essayResult}
                selectedPromptIndex={selectedPromptIndex}
                essayType={essayType}
              />
            }
          />
          <Route path="/revise" element={<ReviseEssay />} />
          <Route path="/resume" element={<ReviseResume />} />
          <Route path="/premium" element={<Premium />} />
          <Route path="/cover" element={<WriteCover />} />

        </Routes>
        <ToastContainer />
        {/* <Footer /> */}
      </BrowserRouter>
    </div>
  )
}

export default App
