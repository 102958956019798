import React from 'react'
import { useEffect } from 'react'
import { schoolDatabase } from '../SchoolsDatabase'
import './WhatAreYouWriting.css'
import waitlist from '../../assets/waitlist.png'
import application from '../../assets/application.png'
import appeal from '../../assets/appeal.png'
import Button from 'react-bootstrap/Button'

const essayTypes = [
  {
    name: 'Application',
    description: 'Write your college application essays.',
    image: application,
  },
  {
    name: 'Letter Of Continued Interest',
    description: 'Write a letter to the admissions dept.',
    image: waitlist,
  },
  {
    name: 'Appeal',
    description: 'Appeal an admission decision.',
    image: appeal,
  },
]

function WhatAreYouWriting({ setEssayType, essayType }) {
  useEffect(() => {}, [])

  const changeSelectedEssayTypeBorder = (essayType) => {
    clearAllEssayTypeBorder()
    var item = document.getElementById(essayType)
    item.classList.add('selected-essay-type')
  }

  const clearAllEssayTypeBorder = () => {
    var divItems = document.getElementsByClassName(
      'individual-essay-type-container',
    )
    Array.from(divItems).forEach((item) => {
      item.classList.remove('selected-essay-type')
    })
  }

  const next = () => {
    const nextPage = document.getElementsByClassName(
      'select-school-container',
    )[0]
    if (nextPage != null) {
      window.scrollTo({
        top: nextPage.offsetTop - 70,
        behavior: 'smooth',
      })
    }
  }

  const mobileScroll = () => {
    if (window.innerWidth <= 480) {
    window.scrollBy(0,1000)
    }
  }


  return (
    <div className="essay-type-page page-container">
      <div className="body-content">
        <div>
          <h2 className="center-text section-heading dark">
            What do you want to write?
          </h2>
          <p className="center-text">Select from the three options below.</p>
        </div>

        <div className="spacing-50px"></div>

        <div className="all-essay-types-container">
          {essayTypes.map((essayType) => (
            <div
              className="individual-essay-type-container"
              id={essayType.name}
              key={essayType.name}
              onClick={() => {
                setEssayType(essayType.name)
                changeSelectedEssayTypeBorder(essayType.name)
                mobileScroll()
              }}
            >
              <img src={essayType.image} className="essay-type-img" />
              <p className="medium-bold center-text"> {essayType.name}</p>
              <div className="gradient-line"></div>
              <p className="center-text">{essayType.description}</p>
            </div>
          ))}
        </div>

        <div className="spacing-50px"></div>

        <Button id="yellow-button" onClick={() => next()}>
          <p className="mb-0">Continue</p>
        </Button>
      </div>
    </div>
  )
}

export default WhatAreYouWriting
