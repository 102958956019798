import pepe from '../assets/pepe.png'

export const LOCIQuestions = [
  'What are your main reasons for wanting to attend this college specifically?'
  ,'Have you had any recent experiences or accomplishments that you feel would strengthen your application?'
  ,'How do you envision yourself fitting into and contributing to the campus community?'
  ,'Is there any additional information about yourself or your academic or extracurricular pursuits that you feel would be relevant to include in your letter?'
]

export const AppealQuestions = [
  'Have you taken any steps to improve your academic or personal circumstances since submitting your application?'
  ,'Why do you believe you would be a good fit for this particular college, and what would you bring to the community as a student?'
  ,'Have you had any interactions with current students or faculty at the college, or visited the campus in person? If so, how did those experiences influence your decision to apply?'
  ,'What specifically do you hope to study or pursue as a career, and how do you believe this college will support and prepare you for those goals?'
  ,'Is there any additional information or documentation that you can provide to support your appeal, such as letters of recommendation or a personal statement?'  
  ]

export const LOCIDatabase = [
  {
    name: 'UC Berkeley',
    image: pepe,
  },
  {
    name: 'UCLA',
    image: pepe,
  },
  {
    name: 'UCSB',
    image: pepe,
  },
  {
    name: 'UCSD',
    image: pepe,
  },
  {
    name: 'UCSC',
    image: pepe,
  },
  {
    name: 'Cal Poly Slo',
    image: pepe,
  },
  {
    name: 'Harvard',
    image: pepe,
  },
  {
    name: 'Yale',
    image: pepe,
  },
  {
    name: 'MIT',
    image: pepe,
  },
  {
    name: 'Carnegie Mellon',
    image: pepe,
  },
  {
    name: 'Princeton',
    image: pepe,
  },
  {
    name: 'Stanford',
    image: pepe,
  },
  {
    name: 'Cal Tech',
    image: pepe,
  },
  {
    name: 'Rice',
    image: pepe,
  },
  {
    name: 'Vanderbilt',
    image: pepe,
  },
  {
    name: 'U Chicago',
    image: pepe,
  },
  {
    name: 'U Michigan',
    image: pepe,
  },
  {
    name: 'Columbia',
    image: pepe,
  },
  {
    name: 'U Penn',
    image: pepe,
  },
  {
    name: 'Cornell',
    image: pepe,
  },
  {
    name: 'Johns Hopkins',
    image: pepe,
  },
  {
    name: 'Northwestern',
    image: pepe,
  },
  {
    name: 'Georgia Tech',
    image: pepe,
  },
]
  
export const generalPrompts = [
  {
    prompt: 'Prompt 1',
    promptDescription:
      '"Some students have a background, identity, interest, or talent that is so meaningful they believe their application would be incomplete without it. If this sounds like you, then please share your story."',
    followUpQuestions: [
      'What is the background, identity, interest, or talent that you believe makes you unique and sets you apart from other applicants? Why do you feel that this is an important part of your identity and who you are?'

      ,'Can you provide examples of how this background, identity, interest, or talent has influenced your life and your decisions? How has it shaped your perspective and your goals?'
      
      ,'How have you demonstrated or showcased this aspect of your identity in your academic and personal endeavors? Can you provide specific examples of how you have incorporated it into your activities or passions?'
      
      ,'What have you learned or gained from this background, identity, interest, or talent? How has it helped you to grow and develop as a person?'
      
      ,'Is there anything else you would like to add about this background, identity, interest, or talent that you think would be relevant for the essay prompt? It might be helpful to consider any additional details or context that might help provide a more complete picture of your journey with this aspect of your identity.'
    ],
  },
  {
    prompt: 'Prompt 2',
    promptDescription:
      '"The lessons we take from obstacles we encounter can be fundamental to later success. Recount a time when you faced a challenge, setback, or failure. How did it affect you, and what did you learn from the experience?"',
    followUpQuestions: [
      'What is the background, identity, interest, or talent that you believe makes you unique and sets you apart from other applicants? Why do you feel that this is an important part of your identity and who you are?'

      ,'Can you provide examples of how this background, identity, interest, or talent has influenced your life and your decisions? How has it shaped your perspective and your goals?'
      
      ,'How have you demonstrated or showcased this aspect of your identity in your academic and personal endeavors? Can you provide specific examples of how you have incorporated it into your activities or passions?'
      
      ,'What have you learned or gained from this background, identity, interest, or talent? How has it helped you to grow and develop as a person?'
      
      ,'Is there anything else you would like to add about this background, identity, interest, or talent that you think would be relevant for the essay prompt? It might be helpful to consider any additional details or context that might help provide a more complete picture of your journey with this aspect of your identity.'  
    ],
  },
  {
    prompt: 'Prompt 3',
    promptDescription:
      '"Reflect on a time when you questioned or challenged a belief or idea. What prompted your thinking? What was the outcome?"',
    followUpQuestions: [
      'Can you think of a specific time when you questioned or challenged a belief or idea? Why did this belief or idea catch your attention?'

      ,'What prompted your thinking and questioning of this belief or idea? Was it something you read, heard, or experienced that sparked your curiosity or caused you to doubt the validity of the belief or idea?'
      
      ,'How did you approach the process of questioning or challenging this belief or idea? Did you conduct research, engage in discussions with others, or try to gain a different perspective on the issue?'
      
      ,'What was the outcome of your questioning or challenging of this belief or idea? Did you come to a new understanding or conclusion, or did you reaffirm your original belief?'
                
      ,'Is there anything else you would like to add about this experience that you think would be relevant for the essay prompt? It might be helpful to consider any additional details or context that might help provide a more complete picture of your journey with questioning and challenging beliefs or ideas.'
    ],
  },
  {
    prompt: 'Prompt 4',
    promptDescription:
      '"Reflect on something that someone has done for you that has made you happy or thankful in a surprising way. How has this gratitude affected or motivated you?"',
    followUpQuestions: [

    ],
  },
  {
    prompt: 'Prompt 5',
    promptDescription:
      '"Discuss an accomplishment, event, or realization that sparked a period of personal growth and a new understanding of yourself or others."',
    followUpQuestions: [
      'Can you think of a specific accomplishment, event, or realization that had a significant impact on your personal growth? It might be helpful to think about a time when you faced a challenge or had a breakthrough that changed your perspective or understanding of yourself or others.'

      ,'How did this experience shape your personal growth and understanding? Can you describe the specific ways in which it changed your thinking or behavior?'
      
      ,'What did you learn from this experience and how have you applied those lessons to your life? It might be helpful to reflect on any insights or takeaways you gained from this experience and how you have incorporated them into your life.'
      
      ,'How has this experience impacted your relationships with others or your view of the world? It might be helpful to consider how this experience has influenced your interactions with others or your perspective on different issues or challenges.'
      
      ,'Is there anything else you would like to add about this experience that you think would be relevant for the essay prompt? It might be helpful to consider any additional details or context that might help provide a more complete picture of your personal growth and understanding.'
    ],
  },
  {
    prompt: 'Prompt 6',
    promptDescription:
      '"Describe a topic, idea, or concept you find so engaging that it makes you lose all track of time. Why does it captivate you? What or who do you turn to when you want to learn more?"',
    followUpQuestions: [
      'What is the topic, idea, or concept that you find most engaging and captivating? Why do you find it so interesting?'

      ,'How do you typically engage with this topic, idea, or concept? Do you enjoy reading about it, discussing it with others, or participating in activities related to it?'
                
      ,'Why do you think this topic, idea, or concept is so captivating for you? What draws you to it and keeps you engaged?'
      
      ,'When you want to learn more about this topic, idea, or concept, who or what do you turn to? Do you have specific sources or resources that you rely on for information and guidance?'
      
      ,'Is there anything else you would like to add about this topic, idea, or concept that you think would be relevant for the essay prompt? It might be helpful to consider any additional details or context that might help provide a more complete picture of your passion for this subject.'
    ],
  }
]

export const UCPrompts = [

  {
    prompt: 'PIQ #1',
    promptDescription:
      '"Describe an example of your leadership experience in which you have positively influenced others, helped resolve disputes or contributed to group efforts over time."',
    followUpQuestions: [
      'Can you think of a specific experience in which you took on a leadership role and positively influenced others? It might be helpful to think about a time when you provided direction or guidance to a group or when you motivated others to take action.',
      'Did you help resolve disputes or contribute to group efforts over time in this experience? It might be helpful to think about specific strategies or approaches you used to bring people together and find common ground.',
      'Can you provide examples of how your leadership and problem-solving skills made a difference in this experience? It might be helpful to think about specific outcomes or results that were achieved as a result of your efforts.',
      'What did you learn from this experience, and how did it shape your approach to leadership and problem-solving in the future? It might be helpful to reflect on any lessons you learned and how you have applied them to your future endeavors.',
      'Is there anything else you would like to add about this experience that you think would be relevant for the essay prompt? It might be helpful to consider any additional details or context that might help provide a more complete picture of your leadership experience.',
    ],
  },
  {
    prompt: 'PIQ #2',
    promptDescription:
      '"Every person has a creative side, and it can be expressed in many ways: problem solving, original and innovative thinking, and artistically, to name a few. Describe how you express your creative side."',
    followUpQuestions: [
      'Can you think of specific ways in which you have expressed your creativity? It might be helpful to think about activities or projects where you used your imagination or original thinking.'
      ,'How do you typically approach problem solving and come up with creative solutions? It might be helpful to think about any techniques or strategies you use to approach problems in a different way.'
      ,'Do you have any artistic talents or hobbies in which you express your creativity? It might be helpful to think about any mediums or techniques you use to create art or other forms of self-expression.'
      ,'How has expressing your creativity impacted your personal or professional life? It might be helpful to think about any benefits or challenges you have encountered as a result of using your creative skills.'
      ,'Is there anything else you would like to add about your creative side or any specific experiences you have had that you think would be relevant for the essay prompt? It might be helpful to consider any additional details or context that might help provide a more complete picture of your creative expression.'
    ],
  },
  {
    prompt: 'PIQ #3',
    promptDescription:
      '"What would you say is your greatest talent or skill? How have you developed and demonstrated that talent over time?"',
    followUpQuestions: [
      'What is the talent or skill that you feel is your greatest strength? Why do you believe this to be the case?'
      ,'How have you developed and demonstrated this talent over time? Can you provide specific examples of how you have worked to improve and showcase your skills in this area?'
      ,'How has this talent or skill helped you in your personal and academic endeavors? Can you provide examples of how it has made a difference in your life?'
      ,'What steps have you taken to continue growing and improving in this area? How do you plan to continue developing and utilizing this talent or skill in the future?'
      ,'Is there anything else you would like to add about your talent or skill that you think would be relevant for the essay prompt? It might be helpful to consider any additional details or context that might help provide a more complete picture of your journey with this talent or skill.'
    ],
  },
  {
    prompt: 'PIQ #4',
    promptDescription:
      '"Describe how you have taken advantage of a significant educational opportunity or worked to overcome an educational barrier you have faced."',
    followUpQuestions: [
      'Can you think of a specific educational opportunity or barrier that has had a significant impact on your academic journey? It might be helpful to think about a time when you were able to take advantage of a new opportunity or when you faced a challenge that you had to work to overcome.'
      ,'What actions did you take to take advantage of this opportunity or overcome this barrier? It might be helpful to think about specific strategies or approaches you used to achieve your goals.'
      ,'Can you provide examples of how this experience has helped you grow or develop as a learner? It might be helpful to think about any new skills or knowledge you gained as a result of this experience.'
      ,'How has this experience influenced your approach to education and your goals for the future? It might be helpful to reflect on any lessons you learned and how you have applied them to your future endeavors.'
      ,'Is there anything else you would like to add about this experience that you think would be relevant for the essay prompt? It might be helpful to consider any additional details or context that might help provide a more complete picture of your educational journey.'
    ],
  },
  {
    prompt: 'PIQ #5',
    promptDescription:
      '"Describe the most significant challenge you have faced and the steps you have taken to overcome this challenge. How has this challenge affected your academic achievement?"',
    followUpQuestions: [
      'Can you think of a specific challenge that has had a significant impact on your life? It might be helpful to consider any personal, academic, or professional challenges you have faced.'
      ,'What steps did you take to overcome this challenge? It might be helpful to think about any strategies or approaches you used to tackle the issue and work through it.'
      ,'How has this challenge affected your academic achievement? Can you provide specific examples of how it impacted your grades, attendance, or other academic indicators?'
      ,'What did you learn from this challenge, and how has it shaped your approach to overcoming future challenges? It might be helpful to reflect on any lessons you learned and how you have applied them to your future endeavors.'
      ,'Is there anything else you would like to add about this challenge that you think would be relevant for the essay prompt? It might be helpful to consider any additional details or context that might help provide a more complete picture of your experience with this challenge.'
    ],
  },
  {
    prompt: 'PIQ #6',
    promptDescription:
      '"Think about an academic subject that inspires you. Describe how you have furthered this interest inside and/or outside of the classroom."',
    followUpQuestions: [
      'What is the academic subject that inspires you and why? Can you think of specific moments or experiences that sparked your interest in this subject?'
      ,'How have you furthered this interest inside the classroom? Can you provide examples of specific courses or projects you have completed that have helped deepen your understanding of this subject?'
      ,'How have you furthered this interest outside the classroom? Have you taken part in any extracurricular activities or pursued any independent studies related to this subject?'
      ,'What have you learned or gained from furthering this interest in both the classroom and outside of it? How has this subject impacted your overall educational experience and goals?'
      ,'Is there anything else you would like to add about your interest in this academic subject that you think would be relevant for the essay prompt? It might be helpful to consider any additional details or context that might help provide a more complete picture of your passion for this subject.'
    ],
  },
  {
    prompt: 'PIQ #7',
    promptDescription:
      '"What have you done to make your school or your community a better place?"',
    followUpQuestions: [
      'Can you think of specific actions or initiatives you have taken to improve your school or community? It might be helpful to think about projects or activities you have participated in, or ways you have advocated for change.'
      ,'What motivated you to take action in these ways? What values or beliefs drive your commitment to making your school or community a better place?'
      ,'Can you provide examples of the impact your actions have had on your school or community? How have you seen positive change as a result of your efforts?'
      ,'What have you learned from your experiences working to improve your school or community? How has this influenced your understanding of social responsibility and civic engagement?'
      ,'Is there anything else you would like to add about your efforts to make your school or community a better place that you think would be relevant for the essay prompt? It might be helpful to consider any additional details or context that might help provide a more complete picture of your involvement and impact.'
    ],
  },
  {
    prompt: 'PIQ #8',
    promptDescription:
      '"Beyond what has already been shared in your application, what do you believe makes you a strong candidate for admissions to the University of California?"',
    followUpQuestions: [
      'What specific strengths or qualities do you bring to the table as a candidate for admissions to the University of California? Think about your academic achievements, extracurricular activities, leadership roles, and any other unique experiences or skills you possess.'
      ,'How have you demonstrated your commitment to your education and your goals? It might be helpful to think about any challenges you have overcome or obstacles you have faced, and how you have persevered through them.'
      ,'How do you envision yourself contributing to the University of California community? It might be helpful to think about any specific programs, clubs, or organizations you are interested in joining, or how you plan to get involved on campus.'
      ,'Is there anything else you would like to add that you think would make you stand out as a strong candidate for admissions to the University of California? It might be helpful to consider any additional details or context that might help illustrate your unique qualities and strengths as a candidate.'
    ],
  },
]

export const schoolDatabase = [
  {
    name: 'UC Berkeley',
    image: pepe,
    essayPrompts: UCPrompts
  },
  {
    name: 'UCLA',
    image: pepe,
    essayPrompts: UCPrompts
  },
  {
    name: 'UCSB',
    image: pepe,
    essayPrompts: UCPrompts
  },
  {
    name: 'UCSD',
    image: pepe,
    essayPrompts: UCPrompts
  },
  {
    name: 'UCSC',
    image: pepe,
    essayPrompts: UCPrompts
  },
  {
    name: 'Cal Poly Slo',
    image: pepe,
    essayPrompts: UCPrompts
  },
  {
    name: 'Harvard',
    image: pepe,
    essayPrompts: generalPrompts
  },
  {
    name: 'Yale',
    image: pepe,
    essayPrompts: generalPrompts
  },
  {
    name: 'MIT',
    image: pepe,
    essayPrompts: generalPrompts
  },
  {
    name: 'Carnegie Mellon',
    image: pepe,
    essayPrompts: generalPrompts
  },
  {
    name: 'Princeton',
    image: pepe,
    essayPrompts: generalPrompts
  },
  {
    name: 'Stanford',
    image: pepe,
    essayPrompts: generalPrompts
  },
  {
    name: 'Cal Tech',
    image: pepe,
    essayPrompts: generalPrompts
  },
  {
    name: 'Rice',
    image: pepe,
    essayPrompts: generalPrompts
  },
  {
    name: 'Vanderbilt',
    image: pepe,
    essayPrompts: generalPrompts
  },
  {
    name: 'U Chicago',
    image: pepe,
    essayPrompts: generalPrompts
  },
  {
    name: 'U Michigan',
    image: pepe,
    essayPrompts: generalPrompts
  },
  {
    name: 'Columbia',
    image: pepe,
    essayPrompts: generalPrompts
  },
  {
    name: 'U Penn',
    image: pepe,
    essayPrompts: generalPrompts
  },
  {
    name: 'Cornell',
    image: pepe,
    essayPrompts: generalPrompts
  },
  {
    name: 'Johns Hopkins',
    image: pepe,
    essayPrompts: generalPrompts
  },
  {
    name: 'Northwestern',
    image: pepe,
    essayPrompts: generalPrompts
  },
  {
    name: 'Georgia Tech',
    image: pepe,
    essayPrompts: generalPrompts
  },
  {
    name: 'University of California',
    image: pepe,
    essayPrompts: generalPrompts
  },
]
