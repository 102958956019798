import React from 'react'
import { useEffect, useState, useLayoutEffect } from 'react'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { config } from '../../App'
import { useLocation } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../Firebase'
import { toast } from 'react-toastify'
import './ReviseResume.css'
function ReviseResume() {
  const navigate = useNavigate()
  const [reviseResumeText, setReviseResumeText] = useState('')
  const [reviseResumePrompt, setReviseResumePrompt] = useState('')
  const [revisedEssay, setRevisedEssay] = useState('')
  const [premiumStatus, setPremiumStatus] = useState('')
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const lastWrittenEssay = location.state ? location.state.essay : ''
  const lastWrittenPrompt = location.state ? location.state.prompt : ''

  const [user, loadingAuth, error] = useAuthState(auth)
  //const premium = checkPremium(user?.email)

  useEffect(() => {
    if (!loadingAuth && !user) {
      toast.dismiss()
      //toast('Please login to use our services.')
      navigate('/login', { state: { origin: 'revise' } })
    }
  }, [user, loadingAuth])

  useEffect(() => {
    changeHeightOfTextArea()
    console.log('lastWrittenPrompt', lastWrittenPrompt)
    if (lastWrittenEssay && lastWrittenPrompt) {
      setReviseResumeText(lastWrittenEssay)
      setReviseResumePrompt(lastWrittenPrompt)
    }
  }, [])

  useEffect(() => {
    changeHeightOfTextArea()
  }, [reviseResumeText, reviseResumePrompt, revisedEssay])

  useEffect(() => {
    if (revisedEssay) {
      const nextPage = document.getElementsByClassName('revised-essay')[0]
      console.log('nextpage', nextPage)
      window.scrollTo({ top: nextPage.offsetTop - 70, behavior: 'smooth' })
    }
  }, [revisedEssay])

  function checkPremium(userEmail) {
    let data = {
      email: String(userEmail),
    }

    console.log("I tried to check premium")
    // Make the POST request using Axios
    const response = axios.post('https://davidzag.pythonanywhere.com/check-member-status',
    data, 
    config,)      
    .then((response) => {
      setPremiumStatus(response.data.result)
      //console.log('premium status', response.data.result)
      setTimeout(1000)
      console.log('premium status', premiumStatus)
    })
  }
  
  const reviseResume = async () => {
    setLoading(true)
    let data = {
      prompt: reviseResumePrompt,
      essay: reviseResumeText,
      user: user.email
    }

    console.log('data', data)

    axios
      .post(
        'https://davidzag.pythonanywhere.com/easyessay-revise-resume',
        data,
        config,
      )
      .then((response) => {
        setRevisedEssay(response.data.result)
        console.log('results', response.data.result)

        setLoading(false)

        // navigate("/results");
      })
  }

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener('resize', changeHeightOfTextArea)
    changeHeightOfTextArea()
    return () => window.removeEventListener('resize', changeHeightOfTextArea)
  }, [])

  const changeHeightOfTextArea = () => {
    var item = document.getElementById('revise-essay-text-area')
    if (item) item.style.height = Math.max(item.scrollHeight, 400) + 'px'

    var item = document.getElementById('revised-essay-text-area')
    if (item) item.style.height = Math.max(item.scrollHeight) + 'px'

    var item = document.getElementById('revise-essay-prompt-area')
    if (item) item.style.height = Math.max(item.scrollHeight) + 'px'
  }

  return (
    <>
      {loading ? (
        <div className="loading-screen-page">
          <h2 className="medium-bold">Writing...</h2>
        </div>
      ) : user ? (
        <>
          <div className="revise-essay-page page-container">
            <div className="body-content">
              <h2 className="center-text section-heading">
                Revise your Resume.
              </h2>
              <p className="center-text margin-0">
                Tailor your resume to the job posting by adding job description. Leave Job Description blank for resume rewrite.
              </p>
              <div className="spacing-50px"></div>
              <textarea
                type="text"
                id="revise-essay-prompt-area"
                className="essay-result-prompt-area"
                onChange={(e) => {
                  setReviseResumePrompt(e.target.value)
                }}
                placeholder={'Paste Job Description here ...'}
                value={reviseResumePrompt}
              ></textarea>
              <div className="spacing-50px"></div>
              <textarea
                type="text"
                id="revise-essay-text-area"
                className="essay-result-text-area"
                onChange={(e) => {
                  setReviseResumeText(e.target.value)
                }}
                placeholder={'Paste your Resume here ...'}
                value={reviseResumeText}
              ></textarea>
              <div className="spacing-50px"></div>
              <div className="horizontal-button-containers">
                <Button
                  id="yellow-button"
                  className="margin-0"
                  onClick={reviseResume}
                >
                  <p className="mb-0">Revise</p>
                </Button>
              </div>
            </div>
          </div>
          {revisedEssay && (
            <div id="revised-essay" className="page-container revised-essay">
              <div className="body-content">
                <div>
                  <h2 className="center-text section-heading">Suggestions</h2>
                  <div className="spacing-50px"></div>
                  <textarea
                    type="text"
                    readOnly={true}
                    id="revised-essay-text-area"
                    className="essay-result-text-area"
                    value={revisedEssay.trim()}
                  ></textarea>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default ReviseResume
