import React from 'react'
import { useEffect, useState, useLayoutEffect } from 'react'
import SelectEssayPrompt from './SelectEssayPrompt'
import SelectSchool from './SelectSchool'
import FollowUpQuestions from './FollowUpQuestions'
import WhatAreYouWriting from './WhatAreYouWriting'
import { schoolDatabase } from '../SchoolsDatabase'
import axios from 'axios'
import './Main.css'
import { config } from '../../App'
import { useNavigate } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../Firebase'
import { toast } from 'react-toastify'

function Main({
  setEssayResult,
  selectedPromptIndex,
  setSelectedPromptIndex,
  selectedSchoolIndex,
  setSelectedSchoolIndex,
  essayType,
  setEssayType,
}) {
  const navigate = useNavigate()

  const [followUpAnswers, setFollowUpAnswers] = useState({})
  const [loadingPage, setLoadingPage] = useState(false)
  const [user, loadingAuth, error] = useAuthState(auth)
  useEffect(() => {
    if (!loadingAuth && !user) {
      toast.dismiss()
      //toast('Please login to use our services.')
      navigate('/login', { state: { origin: 'write' } })
    }
  }, [user, loadingAuth])

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const generateEssay = async () => {
    if (user) {
      setLoadingPage(true)
      if (essayType == 'Application') {
        await generateApplicationEssay()
      } else if (essayType == 'Letter Of Continued Interest') {
        await generateLOCIEssay()
      }
    } else {
      navigate('/login', { state: { origin: 'write' } })
    }
  }

  const generateApplicationEssay = async () => {
    let data = {
      school: schoolDatabase[selectedSchoolIndex].name,
      prompt:
        schoolDatabase[selectedSchoolIndex].essayPrompts[selectedPromptIndex]
          .promptDescription,
      questions: followUpAnswers,
    }
    axios
      .post(
        'https://davidzag.pythonanywhere.com/easyessay-write-essay',
        data,
        config,
      )
      .then((response) => {
        setEssayResult(response.data.result)
        setLoadingPage(false)
        navigate('/results')
      })
  }

  const generateLOCIEssay = async () => {
    let data = {
      school: schoolDatabase[selectedSchoolIndex].name,
      questions: followUpAnswers,
    }
    axios
      .post(
        'https://davidzag.pythonanywhere.com/easyessay-write-LOCI',
        data,
        config,
      )
      .then((response) => {
        setEssayResult(response.data.result)
        setLoadingPage(false)
        navigate('/results')
      })
  }

  return (
    <>
      {loadingPage ? (
        <div className="loading-screen-page">
          <h2 className="medium-bold">Generating Essay...</h2>
        </div>
      ) : user ? (
        <div>
          <WhatAreYouWriting
            setEssayType={setEssayType}
            essayType={essayType}
          />
          <SelectSchool
            setSelectedSchoolIndex={setSelectedSchoolIndex}
            essayType={essayType}
          />
          <SelectEssayPrompt
            selectedSchoolIndex={selectedSchoolIndex}
            setSelectedPromptIndex={setSelectedPromptIndex}
            essayType={essayType}
          />
          <FollowUpQuestions
            selectedSchoolIndex={selectedSchoolIndex}
            selectedPromptIndex={selectedPromptIndex}
            followUpAnswers={followUpAnswers}
            setFollowUpAnswers={setFollowUpAnswers}
            generateEssay={generateEssay}
            essayType={essayType}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default Main
