import React, { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import './Waitlist.css'
import { auth, db, logout } from '../../Firebase'
import { query, collection, getDocs, where } from 'firebase/firestore'
import BuyPlan from '../Payment/BuyPlan'

function Waitlist() {
  const [user, loading, error] = useAuthState(auth)
  const [waitlist, setWaitlist] = useState('')
  const navigate = useNavigate()
  const fetchUserWaitlist = async () => {
    try {
      const q = query(collection(db, 'users'), where('uid', '==', user?.uid))
      const doc = await getDocs(q)
      const data = doc.docs[0].data()
      setWaitlist(data.waitlist)
    } catch (err) {
      console.error(err)
      alert('An error occured while fetching user data')
    }
  }
  useEffect(() => {
    if (loading) return
    if (!user) return navigate('/')
    fetchUserWaitlist()
  }, [user, loading])
  return (
    <>
      {loading ? (
        <div className="loading-screen-page">
          <h2 className="medium-bold">Loading...</h2>
        </div>
      ) : (
        <div className="waitlist">
          <div className="waitlist_container">
            Your current waitlist number
            <div>{waitlist}</div>
            <div>{user?.email}</div>
            <button className="waitlist_btn" onClick={logout}>
              Logout
            </button>
          </div>
          {/* <BuyPlan /> */}
        </div>
      )}
    </>
  )
}
export default Waitlist
