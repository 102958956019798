import { toast } from 'react-toastify'
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from 'firebase/auth'

import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from 'firebase/firestore'

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCRuY4dA__bTw1fHa04Y1aYjxV5jBdHulQ',
  authDomain: 'easyessay-f12d6.firebaseapp.com',
  projectId: 'easyessay-f12d6',
  storageBucket: 'easyessay-f12d6.appspot.com',
  messagingSenderId: '342135831126',
  appId: '1:342135831126:web:0eca5fd9c5c7cb6fbc59f7',
  measurementId: 'G-LE8PT1CJYJ',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
export const auth = getAuth(app)
export const db = getFirestore(app)

const googleProvider = new GoogleAuthProvider()
export const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider)
    const user = res.user
    const q = query(collection(db, 'users'), where('uid', '==', user.uid))
    const docs = await getDocs(q)
    if (docs.docs.length === 0) {
      await addDoc(collection(db, 'users'), {
        uid: user.uid,
        name: user.displayName,
        authProvider: 'google',
        email: user.email,
      })
    }
    toast.dismiss()
  } catch (err) {
    console.error(err)
    toast(err.message)
  }
}

export const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
    toast.dismiss()
  } catch (err) {
    console.error(err)
    toast(err.message)
  }
}

export const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password)
    const user = res.user
    await addDoc(collection(db, 'users'), {
      uid: user.uid,
      name,
      authProvider: 'local',
      email,
    })
    toast.dismiss()
  } catch (err) {
    console.error(err)
    toast(err.message)
  }
}

export const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email)
    toast('Password reset link sent!')
  } catch (err) {
    console.error(err)
    toast(err.message)
  }
}

export const logout = () => {
  signOut(auth)
}
