import './Footer.css'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div className="footer-big-container">
      <div className="landing-separator-faq flipped"></div>
      <div className="footer-container">
        <p className="footer-title bold ">senioritis.ai</p>
        <div className="footer-links">
          <Link to="/essay" className="center-div margin-0">
            <p className="margin-0 ">Job Postings</p>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Footer
