import React from 'react'
import { useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import './Premium.css'
import waitlist from '../../assets/waitlist.png'
import application from '../../assets/application.png'
import appeal from '../../assets/appeal.png'
import Button from 'react-bootstrap/Button'

function Premium({}) {
  useEffect(() => {
    const script = document.createElement('script')

    script.src = '//embed.typeform.com/next/embed.js'
    //   script.async = true;

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <HelmetProvider>
      <div className="premium-page page-container">
        <div className="body-content">
          <h2 className="center-text section-heading">Get expert help with Premium</h2>
          <p className="center-text">
            Skip the waitlist and get instant access, plus get your essay reviewed by our team of admissions experts.
          </p>
          <div className="spacing-50px"></div>

          <div
            data-tf-widget="kJp8jgfa"
            data-tf-opacity="100"
            data-tf-iframe-props="title=Senioritis"
            className="premium-type-form"
            style={{ height: '60vh' }}
          >
            <Helmet>
              <script src="//embed.typeform.com/next/embed.js"></script>
            </Helmet>
          </div>
        </div>
      </div>
    </HelmetProvider>
  )
}

export default Premium
