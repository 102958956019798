import React from 'react'
import { useEffect } from 'react'
import { schoolDatabase } from '../SchoolsDatabase'
import './SelectEssayPrompt.css'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

function SelectEssayPrompt({
  selectedSchoolIndex,
  setSelectedPromptIndex,
  essayType,
}) {
  useEffect(() => {}, [])

  useEffect(() => {
    setSelectedPromptIndex(null)
    clearAllPromptBorder()
  }, [selectedSchoolIndex, essayType])

  const changeSelectedPromptBorder = (promptIndex) => {
    clearAllPromptBorder()
    var item = document.getElementById('prompt' + promptIndex)
    item.classList.add('selected-prompt')
  }

  const clearAllPromptBorder = () => {
    var divItems = document.getElementsByClassName(
      'individual-prompt-container',
    )
    Array.from(divItems).forEach((item) => {
      item.classList.remove('selected-prompt')
    })
  }

  const next = () => {
    const nextPage = document.getElementsByClassName(
      'follow-up-questions-page',
    )[0]
    if (nextPage != null) {
      window.scrollTo({
        top: nextPage.offsetTop - 70,
        behavior: 'smooth',
      })
    }
  }

  const mobileScroll = () => {
    if (window.innerWidth <= 480) {
    window.scrollBy(0,2800)
    }
  }


  const navigateBack = () => {
    const prevPage = document.getElementsByClassName(
      'select-school-container',
    )[0]
    window.scrollTo({
      top: prevPage.offsetTop - 70,
      behavior: 'smooth',
    })
  }

  return (
    <>
      {essayType == 'Application' && selectedSchoolIndex != null && (
        <div className="select-essay-page page-container">
          <div className="body-content">
            <div>
              <h2 className="center-text section-heading">
                Choose an Essay Prompt.
              </h2>
              <p className="center-text">
                choose one of the essay prompts to write.
              </p>
            </div>

            <div className="spacing-50px"></div>

            <div className="all-prompts-container">
              {schoolDatabase[
                selectedSchoolIndex ? selectedSchoolIndex : 0
              ].essayPrompts.map((prompt, index) => (
                <div
                  className="individual-prompt-container"
                  id={'prompt' + index}
                  key={'prompt' + index}
                  onClick={() => {
                    setSelectedPromptIndex(index)
                    changeSelectedPromptBorder(index)
                    mobileScroll()
                  }}
                >
                  <p className="medium-bold">{prompt.prompt}</p>
                  <div className="gradient-line"></div>
                  <p>{prompt.promptDescription}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="spacing-50px"></div>

          <div className="horizontal-button-containers">
            <div className="back-button gradient" onClick={navigateBack}>
              <FontAwesomeIcon className="blue" icon={faArrowLeft} />
              <p className="margin-0 ">Back</p>
            </div>
            <Button
              id="yellow-button"
              className="margin-0"
              onClick={() => next()}
            >
              <p className="mb-0">Continue</p>
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default SelectEssayPrompt
