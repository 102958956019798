import React from 'react'
import { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import './FollowUpQuestions.css'
import {
  LOCIQuestions,
  AppealQuestions,
  schoolDatabase,
} from '../SchoolsDatabase'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

function FollowUpQuestions({
  selectedSchoolIndex,
  selectedPromptIndex,
  setFollowUpAnswers,
  followUpAnswers,
  generateEssay,
  essayType,
}) {
  const [questions, setQuestions] = useState([])

  useEffect(() => {
    changeBackgroundColor()
  }, [])

  useEffect(() => {
    getFollowUpQuestions()
    changeBackgroundColor()
  }, [essayType, selectedSchoolIndex, selectedPromptIndex])

  const getFollowUpQuestions = () => {
    if (essayType == 'Application') {
      setQuestions(
        schoolDatabase[selectedSchoolIndex ? selectedSchoolIndex : 0]
          .essayPrompts[selectedPromptIndex ? selectedPromptIndex : 0]
          .followUpQuestions,
      )
    } else if (essayType === 'Letter Of Continued Interest') {
      setQuestions(LOCIQuestions)
    } else if (essayType === 'Appeal') {
      setQuestions(AppealQuestions)
    }
  }

  const changeBackgroundColor = () => {
    const thisPage = document.getElementsByClassName(
      'follow-up-questions-page',
    )[0]
    if (thisPage) {
      if (essayType == 'Application') {
        thisPage.style.backgroundColor = 'rgb(247, 247, 247)'
      } else {
        thisPage.style.backgroundColor = 'white'
      }
    }
  }

  const navigateBack = () => {
    var prevPage
    if (essayType == 'Application')
      prevPage = document.getElementsByClassName('select-essay-page')[0]
    else
      prevPage = document.getElementsByClassName('select-school-container')[0]

    window.scrollTo({
      top: prevPage.offsetTop - 70,
      behavior: 'smooth',
    })
  }

  return (
    <>
      {((essayType == 'Application' && selectedPromptIndex != null) ||
        (essayType != 'Application' && selectedSchoolIndex != null)) && (
        <div
          className="follow-up-questions-page page-container"
          style={{
            backgroundColor:
              essayType == 'Application' ? 'rgb(247, 247, 247)' : 'white',
          }}
        >
          <div className="follow-up-body-content body-content">
            <h2 className="center-text section-heading">
              Answer these follow up questions.
            </h2>
            <p className="center-text">
              Answer any or all of these follow up questions to help personalize
              your essay.
            </p>

            <div className="spacing-50px"></div>

            <div className="all-follow-up-container">
              {questions.map((question, index) => (
                <div
                  className="question-answer-container"
                  key={'question' + index}
                >
                  <p>{question}</p>
                  <div className="gradient-line"></div>
                  <textarea
                    type="text"
                    className="follow-up-question-input"
                    rows={5}
                    onChange={(e) => {
                      var _answers = followUpAnswers
                      _answers[question] = e.target.value
                      setFollowUpAnswers(_answers)
                    }}
                  />
                </div>
              ))}
            </div>

            <div className="horizontal-button-containers">
              <div className="back-button gradient" onClick={navigateBack}>
                <FontAwesomeIcon className="blue" icon={faArrowLeft} />
                <p className="margin-0 ">Back</p>
              </div>
              <Button
                id="yellow-button"
                className="margin-0"
                onClick={() => generateEssay()}
              >
                <p className="mb-0">Generate Essay</p>
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default FollowUpQuestions
