import React from 'react'
import { useEffect } from 'react'
import { schoolDatabase } from '../SchoolsDatabase'
import { LOCIDatabase } from '../SchoolsDatabase'

import './SelectSchool.css'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

function SelectSchool({ setSelectedSchoolIndex, essayType }) {
  useEffect(() => {}, [])

  const changeSelectedSchoolBorder = (schoolIndex) => {
    clearAllSchoolBorder()
    var item = document.getElementById('school' + schoolIndex)

    item.classList.add('selected-school')
  }

  function getSchoolDB(essayType) {
    if (essayType === "Letter Of Continued Interest") {
      return LOCIDatabase;
    } else if (essayType === "Application") {
      return schoolDatabase;
    } else if (essayType === "Appeal") {
      return LOCIDatabase
    }
  }
  

  const clearAllSchoolBorder = () => {
    var divItems = document.getElementsByClassName(
      'individual-school-container',
    )
    Array.from(divItems).forEach((item) => {
      item.classList.remove('selected-school')
    })

    var divItems = document.getElementsByClassName(
      'individual-school-container-no-pic',
    )
    Array.from(divItems).forEach((item) => {
      item.classList.remove('selected-school')
    })
  }

  const next = () => {
    var nextPage
    if (essayType == 'Application')
      nextPage = document.getElementsByClassName('select-essay-page')[0]
    else
      nextPage = document.getElementsByClassName('follow-up-questions-page')[0]

    if (nextPage != null)
      window.scrollTo({
        top: nextPage.offsetTop - 70,
        behavior: 'smooth',
      })
  }

  const navigateBack = () => {
    const prevPage = document.getElementsByClassName('essay-type-page')[0]
    window.scrollTo({
      top: prevPage.offsetTop - 70,
      behavior: 'smooth',
    })
  }

  return (
    <>
      {essayType && (
        <div className="select-school-container page-container ">
          <div className="body-content">
            <h2 className="center-text section-heading">Select a College.</h2>
            <p className="center-text">
              Pick a college to write the essay for.
            </p>
            <div className="spacing-50px"></div>

            <div className="all-schools-container">
              {getSchoolDB(essayType).map((obj, index) => (
                <div
                  className="individual-school-container-no-pic"
                  id={'school' + index}
                  key={'school' + index}
                  onClick={() => {
                    setSelectedSchoolIndex(index)
                    changeSelectedSchoolBorder(index)
                  }}
                >
                  {obj.name}
                </div>
              ))}
            </div>
          </div>

          <div className="spacing-50px"></div>

          <div className="horizontal-button-containers">
            <div className="back-button gradient" onClick={navigateBack}>
              <FontAwesomeIcon className="blue" icon={faArrowLeft} />
              <p className="margin-0 ">Back</p>
            </div>
            <Button
              id="yellow-button"
              className="margin-0"
              onClick={() => next()}
            >
              <p className="mb-0">Continue</p>
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default SelectSchool
