import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  auth,
  signInWithGoogle,
  logInWithEmailAndPassword,
} from '../../Firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import './Login.css'
import { useLocation } from 'react-router-dom'

function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [user, loading, error] = useAuthState(auth)
  const navigate = useNavigate()
  const location = useLocation()
  const origin = location.state ? location.state.origin : ''

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return
    }
    if (user) {
      if (origin == 'write') {
        navigate('/revise')
      } else if (origin == 'revise') {
        navigate('/revise')
      } else {
        navigate('/')
      }
    }
  }, [user, loading])
  return (
    <>
      {loading ? (
        <div className="loading-screen-page">
          <h2 className="medium-bold">Loading...</h2>
        </div>
      ) : (
        <div className="login">
          <div className="login__container">
            <input
              type="text"
              className="login__textBox"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-mail Address"
            />
            <input
              type="password"
              className="login__textBox"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
            <button
              className="login__btn"
              onClick={() => logInWithEmailAndPassword(email, password)}
            >
              Login
            </button>
            <button
              className="login__btn login__google"
              onClick={signInWithGoogle}
            >
              Login with Google
            </button>
            <div>
              <Link to="/reset">Forgot Password</Link>
            </div>
            <div>
              Don't have an account? <Link to="/register">Register</Link> now.
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default Login
