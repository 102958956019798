import './Navigation.css'
import { Navbar, Nav } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, logout } from '../Firebase'

function Navigation({}) {
  const [user, loading, error] = useAuthState(auth)

  useEffect(() => {}, [])

  return (
    <Navbar collapseOnSelect bg="dark" variant="dark" expand="lg" sticky="top">
      <div id="navbarcontainer" className="width-80">
        <Nav>
          <Nav.Link href="/" className="mr-1">
            <div className="logo">
              <p className="white margin-0" style={{ fontWeight: '900' }}>
                senioritis.ai
              </p>
            </div>
          </Nav.Link>
        </Nav>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            {user ? (
              <></>
            ) : (
              <>
                <Nav.Link href="/login" className="mr-1">
                  <p className="white margin-0">Login</p>
                </Nav.Link>
                <Nav.Link href="/register" className="mr-1">
                  <p className="white margin-0">Sign Up</p>
                </Nav.Link>
              </>
            )}

            {user ? (
              <>
                <Nav.Link href="/revise" className="mr-1">
                  <p className="white margin-0">Cover Letter</p>
                </Nav.Link>
                <Nav.Link href="/resume" className="mr-1">
                  <p className="white margin-0">Resume Revision</p>
                </Nav.Link>
                <Nav.Link onClick={logout} className="mr-1">
                  <p className="white margin-0">Logout</p>
                </Nav.Link>
              </>
            ) : (
              <></>
            )}
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}

export default Navigation
