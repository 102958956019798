import React from 'react'
import { useEffect, useState, useLayoutEffect } from 'react'
import './WriteCover.css'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { config } from '../../App'
import { useLocation } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../Firebase'
import { toast } from 'react-toastify'
import './WriteCover.css'
function WriteCover() {
  const navigate = useNavigate()
  const [reviseEssayText, setReviseEssayText] = useState('')
  const [reviseEssayPrompt, setReviseEssayPrompt] = useState('')
  const [revisedEssay, setRevisedEssay] = useState('')
  const [loading, setLoading] = useState(false)
  const location = useLocation()

  const [user, loadingAuth, error] = useAuthState(auth)


  useEffect(() => {
    changeHeightOfTextArea()
  }, [reviseEssayText, reviseEssayPrompt, revisedEssay])

  useEffect(() => {
    if (revisedEssay) {
      const nextPage = document.getElementsByClassName('revised-essay')[0]
      console.log('nextpage', nextPage)
      window.scrollTo({ top: nextPage.offsetTop - 70, behavior: 'smooth' })
    }
  }, [revisedEssay])

  const reviseEssay = async () => {
    setLoading(true)
    let data = {
      requirements: reviseEssayText,
    }

    console.log('data', data)

    axios
      .post(
        'https://davidzag.pythonanywhere.com/poop',
        data,
        config,
      )
      .then((response) => {
        setRevisedEssay(response.data.result)
        console.log('results', response.data.result)

        setLoading(false)

      })
  }

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener('resize', changeHeightOfTextArea)
    changeHeightOfTextArea()
    return () => window.removeEventListener('resize', changeHeightOfTextArea)
  }, [])

  const changeHeightOfTextArea = () => {
    var item = document.getElementById('revise-essay-text-area')
    if (item) item.style.height = Math.max(item.scrollHeight, 400) + 'px'

    var item = document.getElementById('revised-essay-text-area')
    if (item) item.style.height = Math.max(item.scrollHeight) + 'px'

    var item = document.getElementById('revise-essay-prompt-area')
    if (item) item.style.height = Math.max(item.scrollHeight) + 'px'
  }

  return (
    <>
      {loading ? (
        <div className="loading-screen-page">
          <h2 className="medium-bold">Crafting your cover letter...</h2>
        </div>
      ) : user ? (
        <>
          <div className="revise-essay-page page-container">
            <div className="body-content">
              <h2 className="center-text section-heading">
                Cover Letter Generator.
              </h2>
              <p className="center-text margin-0">
                Write a custom cover letter specifically for the specific job requirements.
              </p>
              <div className="spacing-50px"></div>
              <textarea
                type="text"
                id="revise-essay-text-area"
                className="essay-result-text-area"
                onChange={(e) => {
                  setReviseEssayText(e.target.value)
                }}
                placeholder={'Paste job requirements (format doesnt matter) ...'}
                value={reviseEssayText}
              ></textarea>
              <div className="spacing-50px"></div>
              <div className="horizontal-button-containers">
                <Button
                  id="yellow-button"
                  className="margin-0"
                  onClick={reviseEssay}
                >
                  <p className="mb-0">Write Cover</p>
                </Button>
              </div>
            </div>
          </div>
          {revisedEssay && (
            <div id="revised-essay" className="page-container revised-essay">
              <div className="body-content">
                <div>
                  <h2 className="center-text section-heading">Your cover letter.</h2>
                  <div className="spacing-50px"></div>
                  <textarea
                    type="text"
                    readOnly={true}
                    id="revised-essay-text-area"
                    className="essay-result-text-area"
                    value={revisedEssay.trim()}
                  ></textarea>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default WriteCover
