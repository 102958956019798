import React from 'react'
import { useEffect, useState, useLayoutEffect } from 'react'
import './OutputEssay.css'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'
import { schoolDatabase } from '../SchoolsDatabase'
function OutputEssay({
  essayResult,
  selectedPromptIndex,
  selectedSchoolIndex,
  essayType,
}) {
  const navigate = useNavigate()
  const [essayResultTextBox, setEssayResultTextBox] = useState(
    essayResult && essayResult.trim(),
  )

  useEffect(() => {
    if (essayResult == null) {
      navigate('/essay')
    }
    changeHeightOfTextArea()
  }, [])

  useEffect(() => {
    changeHeightOfTextArea()
  }, [essayResultTextBox])

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener('resize', changeHeightOfTextArea)
    changeHeightOfTextArea()
    return () => window.removeEventListener('resize', changeHeightOfTextArea)
  }, [])

  const changeHeightOfTextArea = () => {
    var item = document.getElementById('essay-result-text-area')
    item.style.height = item.scrollHeight + 'px'
  }

  const emailMeACopy = () => {
    console.log('state', {
      essay: essayResultTextBox,
      prompt:
        schoolDatabase[selectedSchoolIndex ? selectedSchoolIndex : 0]
          .essayPrompts[selectedPromptIndex],
    })
    let prompt

    if (essayType == 'Application') {
      prompt =
        schoolDatabase[selectedSchoolIndex ? selectedSchoolIndex : 0]
          .essayPrompts[selectedPromptIndex].promptDescription
    } else if (essayType == 'Letter Of Continued Interest') {
      prompt = 'Write a letter of continued interest'
    } else if (essayType == 'Appeal') {
      prompt = 'Write an appeal letter for a university application'
    }

    navigate('/revise', {
      state: {
        essay: essayResultTextBox,
        prompt: prompt,
      },
    })
  }

  return (
    <>
      <div className="essay-result-page page-container">
        <div className="body-content">
          <h2 className="center-text section-heading">
            Here is your generated essay.
          </h2>

          <div className="spacing-50px"></div>
          <textarea
            type="text"
            id="essay-result-text-area"
            className="essay-result-text-area"
            onChange={(e) => {
              setEssayResultTextBox(e.target.value)
            }}
            value={essayResultTextBox && essayResultTextBox.trimStart()}
          />

          <div className="spacing-50px"></div>
          <div className="horizontal-button-containers">
            <Button
              id="yellow-button"
              className="margin-0"
              onClick={emailMeACopy}
            >
              <p className="mb-0">Revise</p>
            </Button>
            <Button
              id="yellow-button"
              className="margin-0"
              onClick={() => navigate('/essay')}
            >
              <p className="mb-0">Write Another Essay</p>
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default OutputEssay
