import React from 'react'
import { useEffect, useState, useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import './Home.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import BuyPlan from '../Payment/BuyPlan'
function Home() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="home-page page-container">
        <div className="home-page-content body-content">
          <div className="home-header"></div>
          <h1 className="website-title-home-page">Land your dream job.</h1>

          <h2>Write the perfect cover letter in seconds, tailored to your own resume.</h2>

          <div className="spacing-25px"></div>

          <div className="horizontal-button-containers">
            <Link to="/revise" className="center-div margin-0">
              <Button id="yellow-button">
                Write Cover Letters
                <FontAwesomeIcon className="arrow-right" icon={faArrowRight} />
              </Button>
            </Link>
            <Link to="/revise" className="center-div margin-0">
              <Button id="yellow-button">
                Revise Resumes
                <FontAwesomeIcon className="arrow-right" icon={faArrowRight} />
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <div className="how-it-works page-container">
        <div className="body-content">
          <h2 className="center-text section-heading white">How It Works.</h2>
          <div className="spacing-50px"></div>
          <div className="how-it-works-cards-container">
            <div className="how-it-words-card">
              <p className="medium-bold center-text">1. Paste in the job posting.</p>
              <div className="gradient-line"></div>
              <p className="center-text">
                A good cover letter gets your resume seen faster.
              </p>
            </div>
            <div className="how-it-words-card">
              <p className="medium-bold center-text">
                2. Submit your resume.
              </p>
              <div className="gradient-line"></div>
              <p className="center-text">
                We create unique, personable cover letters to make a good impression.
              </p>
            </div>
            <div className="how-it-words-card">
              <p className="medium-bold center-text">3. We create your cover letter.</p>
              <div className="gradient-line"></div>
              <p className="center-text">
                See results in seconds. Create as many versions as you want.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
